import { forwardRef, InputHTMLAttributes, Ref } from 'react'
import styled from 'styled-components'

import { addAlphaToColor } from 'common/utils/style'

import { Text } from '../UI/Text'

export type CheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
> & {
  label?: string | React.ReactNode
  variant?: 'right' | 'left'
  indeterminated?: boolean
}

export const Checkbox = forwardRef(function Checkbox(
  {
    label,
    className,
    style,
    disabled,
    variant = 'left',
    indeterminated = false,
    ...props
  }: CheckboxProps,
  ref: Ref<HTMLInputElement>
): JSX.Element {
  return (
    <Wrapper className={className} style={style} isDisabled={disabled}>
      {label && variant === 'right' && (
        <TextLabel position={variant} variant="sixteen" as="span">
          {label}
        </TextLabel>
      )}

      <Input
        type="checkbox"
        position={variant}
        ref={ref}
        disabled={disabled}
        {...props}
      />

      {indeterminated ? <InderterminateControl /> : <CheckboxControl />}

      {label && variant === 'left' && (
        <TextLabel position={variant} variant="sixteen" as="span">
          {label}
        </TextLabel>
      )}
    </Wrapper>
  )
})

const Input = styled.input<{ position: 'right' | 'left' }>`
  position: absolute;
  opacity: 0;
  z-index: -1;
  overflow: hidden;

  ${({ theme }) => theme.media.lg} {
    left: ${({ position }) => (position === 'left' ? '0' : 'unset')};
    right: ${({ position }) => (position === 'right' ? '0' : 'unset')};
  }
`

const Wrapper = styled.label<{ isDisabled?: boolean }>`
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;

  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
`

const CheckboxControl = styled.div`
  position: relative;

  display: block;
  flex-shrink: 0;

  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.375rem;

  background: 1px solid ${({ theme }) => theme.colors.background.default};
  border: 1px solid
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 20)};

  input ~ & {
    transition: box-shadow ${({ theme }) => theme.transitions.ease('250ms')};
  }

  input:focus ~ & {
    box-shadow: 0 0 0 3px
      ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 30)};
  }

  input:checked ~ & {
    background: ${({ theme }) => theme.colors.foreground.default};
    border: 1px solid ${({ theme }) => theme.colors.foreground.default};

    &:after {
      content: '';
      display: block;
      position: absolute;

      height: 0.5625rem;
      width: 0.25rem;
      top: 0.125rem;
      left: 0.375rem;

      border-bottom: 2px solid ${({ theme }) => theme.colors.background.default};
      border-right: 2px solid ${({ theme }) => theme.colors.background.default};

      transform: rotate(45deg);
    }
  }

  input:disabled ~ & {
    background: ${({ theme }) =>
      addAlphaToColor(theme.colors.background.subtle)};
  }
`

const InderterminateControl = styled.div`
  position: relative;

  display: block;
  flex-shrink: 0;

  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.375rem;

  background: ${({ theme }) => theme.colors.background.default};
  border: 1px solid
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 20)};

  input ~ & {
    transition: box-shadow ${({ theme }) => theme.transitions.ease('250ms')};
  }

  input:focus ~ & {
    box-shadow: 0 0 0 3px
      ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 30)};
  }

  background: ${({ theme }) => theme.colors.foreground.default};
  border: 1px solid ${({ theme }) => theme.colors.foreground.default};

  &:after {
    content: '';
    display: block;
    position: absolute;

    height: 1.2px;
    width: 11px;
    top: 50%;
    left: 3.5px;

    border-top: 2px solid ${({ theme }) => theme.colors.background.default};
  }

  input:disabled ~ & {
    background: ${({ theme }) =>
      addAlphaToColor(theme.colors.background.subtle)};
  }
`

const TextLabel = styled(Text)<{ position: 'right' | 'left' }>`
  padding-left: ${({ position }) => (position === 'left' ? '0.5rem' : '0rem')};
  padding-right: ${({ position }) =>
    position === 'right' ? '0.5rem' : '0rem'};

  input:disabled ~ & {
    opacity: 0.75;
  }

  a {
    color: ${({ theme }) => theme.colors.accent.secondary};
    text-decoration: underline;
  }
`
