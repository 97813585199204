import dayjs, { extend as dayJsExtend } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

require('dayjs/locale/da')
require('dayjs/locale/de')
require('dayjs/locale/fr')
require('dayjs/locale/it')
require('dayjs/locale/nl')
require('dayjs/locale/nn')
require('dayjs/locale/pl')
require('dayjs/locale/ro')
require('dayjs/locale/sv')

dayJsExtend(localizedFormat)

export const formatDateLocale = (date: string, locale: string): string => {
  return dayjs(date).locale(locale).format('ll')
}

export const formatDatetimeLocale = (
  datetime: string,
  locale: string
): string => {
  return dayjs(datetime).locale(locale).format('llll')
}
