import { HTMLAttributes } from 'react'
import styled, { css, DefaultTheme } from 'styled-components'

type GapType = 'small' | 'default' | 'big'

export type ButtonGroupProps = HTMLAttributes<HTMLDivElement> & {
  gap?: GapType
  // From which breakpoint should the buttons flow inline.
  inlineFrom?: keyof DefaultTheme['breakpoints']
  isFull?: boolean
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  gap = 'default',
  inlineFrom = 'md',
  isFull,
  children,
  ...props
}) => {
  return (
    <StyledButtonGroup
      inlineFrom={inlineFrom}
      gap={gap}
      isFull={!!isFull}
      {...props}
    >
      {children}
    </StyledButtonGroup>
  )
}

const getGap = (gap: GapType): string => {
  const gaps = {
    small: '0.5rem',
    default: '1rem',
    big: '1.5rem',
  }

  return gaps[gap]
}

type StyledBGProps = Required<
  Pick<ButtonGroupProps, 'gap' | 'inlineFrom' | 'isFull'>
>

const StyledButtonGroup = styled.div<StyledBGProps>`
  display: inline-flex;
  flex-wrap: wrap;

  // stacked
  @media screen and (max-width: ${({ theme, inlineFrom }) =>
      theme.breakpoints[inlineFrom]}) {
    flex-direction: column;
    width: ${({ isFull }) => (isFull ? `100%` : 'auto')};

    align-items: inherit;

    & > *:not(:last-child) {
      margin-bottom: ${({ gap }) => getGap(gap)};
    }
  }

  // inline
  ${({ theme, inlineFrom }) => theme.media[inlineFrom]} {
    flex-direction: row;

    ${({ gap, isFull }) => css`
      width: ${isFull ? `calc(100% + ${getGap(gap)})` : 'auto'};
      margin: -${getGap(gap)} 0 0 -${getGap(gap)};

      & > *:not(:last-child) {
        margin-bottom: unset;
      }

      & > * {
        margin: ${getGap(gap)} 0 0 ${getGap(gap)};
      }
    `}
  }
`
