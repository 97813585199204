import { DOMAttributes } from 'react'
import styled from 'styled-components'

import { addAlphaToColor, dashedLine } from 'common/utils/style'

import { Button, ButtonProps } from './Button'
import { IconOptions } from './Icon'
import { Text } from './Text'

export type Item = {
  label: string
  value: string | null
  icon?: IconOptions
  disabled?: boolean
  onClick?: DOMAttributes<HTMLButtonElement>['onClick']
}

type MenuItem = {
  label?: string
  items: Item[]
}

type FooterButton = Pick<
  ButtonProps,
  'onClick' | 'variant' | 'isLoading' | 'leftIcon' | 'rightIcon'
> & { label: string }

export type MenuProps = {
  menu: MenuItem[]
  onSelect?: (item: Item) => void
  footer?: [FooterButton] | [FooterButton, FooterButton]
}

export const Menu = ({ menu, onSelect, footer }: MenuProps): JSX.Element => {
  return (
    <Wrapper>
      <Content>
        {menu.map((menuItem, i) => (
          <Holder key={i}>
            {menuItem.label && (
              <MenuLabel variant="twelve/caps" as="span">
                {menuItem.label}
              </MenuLabel>
            )}
            {menuItem.items.map((item, j) => (
              <Button
                key={j}
                variant="text"
                size="small"
                leftIcon={item.icon}
                isFull
                onClick={(e) => {
                  item.onClick?.(e)
                  onSelect?.(item)
                }}
              >
                {item.label}
              </Button>
            ))}
          </Holder>
        ))}
      </Content>
      {footer && (
        <Footer>
          {footer.map(({ label, ...buttonProps }, i) => (
            <Button key={i} size="small" {...buttonProps}>
              {label}
            </Button>
          ))}
        </Footer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.background.subtle};
  background-color: ${({ theme }) => theme.colors.background.default};
  box-shadow: ${({ theme }) => theme.shadows.medium};

  display: inline-flex;
  flex-direction: column;

  width: 20rem;
`

const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: 18rem;
`

const MenuLabel = styled(Text)`
  display: block;
  margin: 0.5rem 0;
  padding: 0 0.75rem;

  opacity: 0.75;
`

const Holder = styled.div`
  padding: 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.background.subtle};
  }

  & > button {
    display: block;
    text-align: left;
    border-radius: 0.75rem;
    padding: 0.5rem 0.75rem;
    height: auto;

    span {
      line-height: 1.2;
      white-space: normal;
    }

    &:focus {
      background-color: ${({ theme }) =>
        addAlphaToColor(theme.colors.foreground.default, 8)};
    }

    &:focus:not(:focus-visible) {
      box-shadow: inherit;
    }

    &:focus-visible {
      background-color: ${({ theme }) =>
        addAlphaToColor(theme.colors.foreground.default, 8)};
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;
        background-color: ${({ theme }) =>
          addAlphaToColor(theme.colors.foreground.default, 8)};
      }
    }
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 1.25rem;
  ${({ theme }) => dashedLine('top', theme.colors.background.subtle)};
`
