export const getSessionStorage = (key: string): string | null => {
  try {
    return sessionStorage.getItem(key)
  } catch (error) {
    // noop
    return null
  }
}

export const setSessionStorage = (key: string, value: string): void => {
  try {
    sessionStorage.setItem(key, value)
  } catch (error) {
    // noop
  }
}

export const getLocalStorage = (key: string): string | null => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    // noop
    return null
  }
}

export const setLocalStorage = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value)
  } catch (error) {
    // noop
  }
}
