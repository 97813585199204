import { EmptyObject } from 'react-hook-form'

type UnknownObject = Record<string, unknown>

export const getFlattenedKeysFromObject = (
  obj: UnknownObject,
  separator = '.'
): string[] => {
  const keys: string[] = []

  const iterate = (object: UnknownObject, prevKey?: string) => {
    Object.keys(object).forEach((key) => {
      const currKey = prevKey ? `${prevKey}${separator}${key}` : key
      if (typeof object[key] === 'object' && typeof object[key] !== null) {
        return iterate(object[key] as UnknownObject, currKey)
      } else {
        keys.push(currKey)
      }
    })
  }

  iterate(obj)

  return keys
}

function isNeverObject(obj: unknown): obj is Record<never, never> {
  return (
    typeof obj === 'object' && obj !== null && Object.keys(obj).length === 0
  )
}

export const getKey = (
  obj: UnknownObject | EmptyObject | Record<never, never>,
  key: string
): unknown => {
  return isNeverObject(obj) ? null : obj[key]
}

export const prettify = (obj: unknown): string => {
  return JSON.stringify(obj, null, 2)
}
