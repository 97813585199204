import { createContext, Dispatch, useContext, useEffect, useState } from 'react'

type CurrencyContextType = {
  currentCurrency?: string | null
  setCurrentCurrency: Dispatch<string> | null
}

const CurrencyContext = createContext<CurrencyContextType>({
  setCurrentCurrency: null,
})

export const CurrencyProvider: React.FC<{
  initialCurrency: string | null
  children: React.ReactNode
}> = ({ initialCurrency, children }) => {
  const [currentCurrency, setCurrentCurrency] = useState(initialCurrency)
  return (
    <CurrencyContext.Provider value={{ currentCurrency, setCurrentCurrency }}>
      {children}
    </CurrencyContext.Provider>
  )
}

export const useCurrencyContext = (
  initialCurrency?: string
): CurrencyContextType => {
  const context = useContext(CurrencyContext)

  useEffect(
    function setInitialValue() {
      if (initialCurrency) {
        context.setCurrentCurrency?.(initialCurrency)
      }
    },
    [context, initialCurrency]
  )

  if (context === undefined && process.env.NODE_ENV !== 'production') {
    throw new Error(
      'useCurrencyContext must be used within a CurrencyContext.Provider'
    )
  }

  return context
}
