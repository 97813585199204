import { IconOptions } from './Icon'
import { Tag } from './Tag'

export type ColorTagProps = {
  label: string
  color?: string
  icon?: IconOptions
}

export const ColorTag = ({
  label,
  color = 'foreground',
  icon,
  ...props
}: ColorTagProps): JSX.Element => {
  const colors: Record<string, string> = {
    foreground: 'palette.brand.200',
    blue: 'palette.brand.200',
    yellow: 'palette.yellow.default',
    orange: 'palette.orange.default',
    green: 'palette.green.default',
    pink: 'palette.pink.default',
    purple: 'palette.purple.default',
    beige: 'palette.beige.default',
  }

  return (
    <Tag
      icon={icon}
      label={label}
      color={colors[color || 'foreground']}
      {...props}
    />
  )
}
