import {
  storyblokEditable as sbStoryblokEditable,
  SbBlokData,
} from '@storyblok/react'
import { useRouter } from 'next/router'

export const storyblokEditable = (blok: SbBlokData) => {
  const { isPreview } = useRouter()

  return isPreview ? sbStoryblokEditable(blok) : {}
}
