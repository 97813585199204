import { NextSeo } from 'next-seo'
import React from 'react'
import { ISbStoryData } from 'storyblok-js-client'

import { SITE_ORIGIN } from 'common/constants'
import { upperCaseLocaleRegion } from 'common/utils/i18n'
import { removeTrailingSlash } from 'common/utils/string'
import { useDataContext } from 'lib/dataContext'

export const Seo = ({ story }: { story: ISbStoryData }): JSX.Element => {
  const { alternates } = useDataContext()

  const noIndex = story.content?.should_index === 'no'

  return (
    <NextSeo
      title={story.content?.seo?.title}
      description={story.content?.seo?.description}
      canonical={
        story.content?.canonical_url ||
        removeTrailingSlash(`${SITE_ORIGIN}${story.full_slug}`)
      }
      noindex={noIndex}
      nofollow={noIndex}
      openGraph={{
        type: 'website',
        url: removeTrailingSlash(`${SITE_ORIGIN}${story.full_slug}`),
        title:
          story.content?.seo?.og_title ||
          story.content?.seo?.twitter_title ||
          story.content?.seo?.title,
        description:
          story.content?.seo?.og_description ||
          story.content?.seo?.twitter_description ||
          story.content?.seo?.description,
        images:
          story.content?.seo?.og_image || story.content?.seo?.twitter_image
            ? [
                {
                  // we're not adding both because probably they will be the same and it's not
                  // very professional to have two very similar images
                  url: (story.content?.seo?.og_image ||
                    story.content?.seo?.twitter_image) as string,
                },
              ]
            : [{ url: `${SITE_ORIGIN}og.png` }],
      }}
      languageAlternates={[
        {
          hrefLang: 'x-default',
          href: SITE_ORIGIN,
        },
        {
          hrefLang: upperCaseLocaleRegion(story.full_slug?.split('/')[0]),
          href: removeTrailingSlash(`${SITE_ORIGIN}${story.full_slug}`),
        },
        ...alternates.map((alternate) => ({
          hrefLang: upperCaseLocaleRegion(alternate.split('/')[0]),
          href: removeTrailingSlash(`${SITE_ORIGIN}${alternate}`),
        })),
      ]}
    />
  )
}
