import NextImage from 'next/image'
import styled from 'styled-components'

import { ButtonLink, Container, Text, Theme } from 'common/UI'
import { getImageAttributes, textByLine } from 'common/utils/content'
import { useDataContext } from 'lib/dataContext'
import { CMSLink } from 'modules/shared'

export const PreFooter = (): JSX.Element | null => {
  const { storyUuid, config } = useDataContext()

  if (!config?.content) return null

  const {
    prefooter_title: title,
    prefooter_description: description,
    prefooter_image: image,
    prefooter_button_label: button_label,
    prefooter_button_link: button_link,
    prefooter_blacklist: blacklist,
    prefooter_call_button_label: call_button_label,
    prefooter_call_button_link: call_button_link,
    prefooter_secondary_image: secondary_image,
  } = config.content

  if (storyUuid && blacklist?.includes(storyUuid)) return null

  return (
    <Theme colorTheme="dark-blue">
      <Wrapper>
        <InnerWrapper>
          <Holder>
            <WrapperImages>
              {image && image.filename && (
                <ImageHolder variant="large">
                  <NextImage
                    {...getImageAttributes(image)}
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </ImageHolder>
              )}
              {secondary_image && secondary_image.filename && (
                <>
                  <WrapperImagesInner>
                    <ImageHolder variant="small">
                      <NextImage
                        {...getImageAttributes(secondary_image)}
                        style={{ objectFit: 'cover', height: '100%' }}
                      />
                    </ImageHolder>

                    <GradientDiv />
                  </WrapperImagesInner>
                  <StyledLines src="/assets/pre-footer-lines.svg" alt="lines" />
                </>
              )}
            </WrapperImages>
            <Content>
              {title && (
                <Text
                  as="h3"
                  variant="title/large"
                  css={{ marginBottom: '1rem' }}
                >
                  {textByLine(title, (part, i, length) => (
                    <>
                      {part}
                      {i + 1 < length && <br />}
                    </>
                  ))}
                </Text>
              )}
              {description && (
                <Text as="p" variant="twenty" css={{ marginBottom: '2rem' }}>
                  {textByLine(description, (part, i, length) => (
                    <>
                      {part}
                      {i + 1 < length && <br />}
                    </>
                  ))}
                </Text>
              )}
              <div css={{ display: 'flex', gap: '.75rem', flexWrap: 'wrap' }}>
                {call_button_label && call_button_link && (
                  <ButtonLink
                    href={`tel:${call_button_link.url}`}
                    leftIcon="phone"
                  >
                    {call_button_label}
                  </ButtonLink>
                )}
                {button_label && button_link && (
                  <CMSLink href={button_link} variant="secondary">
                    {button_label}
                  </CMSLink>
                )}
              </div>
            </Content>
          </Holder>
        </InnerWrapper>
      </Wrapper>
    </Theme>
  )
}

const Wrapper = styled.section`
  overflow: hidden;
`

const Holder = styled(Container)`
  text-align: center;

  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg} {
    text-align: left;

    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const InnerWrapper = styled.div`
  padding: 1rem 0rem 1rem 0rem;

  ${({ theme }) => theme.media.md} {
    padding: 3.5rem 0rem;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-top: 3rem;

  ${({ theme }) => theme.media.lg} {
    margin: 0;
    margin-right: 4rem;
    max-width: 30rem;
  }
`

const WrapperImages = styled.div`
  display: flex;
  gap: 0.5rem;
  position: relative;

  ${({ theme }) => theme.media.md} {
    gap: 1rem;
    max-height: 21.75rem;
  }
`

const WrapperImagesInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 60%;

  ${({ theme }) => theme.media.md} {
    gap: 1rem;
    width: 60%;
  }

  ${({ theme }) => theme.media.lg} {
    width: 60%;
  }
`

const ImageHolder = styled.div<{ variant: 'small' | 'large' }>`
  border-top-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  overflow: hidden;
  width: 100%;

  z-index: 2;

  img {
    height: 100%;
    width: 100%;
  }

  ${({ theme }) => theme.media.md} {
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 3rem;
  }
`

const GradientDiv = styled.div`
  height: 3.75rem;
  display: block;
  width: 100%;
  background: ${({ theme }) =>
    `linear-gradient(135deg, ${theme.colors.palette.hyacinth.default}, ${theme.colors.palette.aqua.dark})`};
  border-radius: 0.75rem;
  z-index: 2;

  ${({ theme }) => theme.media.sm} {
    height: 34%;
    border-radius: 1rem;
  }
  ${({ theme }) => theme.media.md} {
    height: 7.0625rem;
  }
`

const StyledLines = styled.img`
  scale: 0.5;
  position: absolute;
  right: -76px;
  top: -106px;
  z-index: 1;

  ${({ theme }) => theme.media.md} {
    scale: 1;
    right: -57px;
    top: -57px;
  }
`
