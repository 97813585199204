import { ISbStoryData } from 'storyblok-js-client'

import { OpenEveningStoryblok } from 'common/types'
import { DynamicBlock } from 'modules/Blocks/DynamicBlock'
import { Layout } from 'modules/Layout/Layout'
import { Seo } from 'modules/shared/Seo'

type Props = {
  story: ISbStoryData<OpenEveningStoryblok>
}

export const OpenEveningPage = ({ story }: Props): JSX.Element => {
  return (
    <Layout>
      <Seo story={story} />

      {story.content.body &&
        story.content.body.map((block) => (
          <DynamicBlock key={block._uid} block={block} />
        ))}
    </Layout>
  )
}
