import { forwardRef, InputHTMLAttributes, Ref } from 'react'
import styled from 'styled-components'

import { addAlphaToColor } from 'common/utils/style'

import { Text } from './Text'

export type RadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label?: string
}

export const Radio = forwardRef(function Radio(
  { label, className, style, disabled, ...props }: RadioProps,
  ref: Ref<HTMLInputElement>
): JSX.Element {
  return (
    <Wrapper className={className} style={style} isDisabled={disabled}>
      <Input type="radio" ref={ref} disabled={disabled} {...props} />
      <RadioControl />
      {label && (
        <TextLabel variant="sixteen/regular" as="span">
          {label}
        </TextLabel>
      )}
    </Wrapper>
  )
})

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  overflow: hidden;
`

const Wrapper = styled.label<{ isDisabled?: boolean }>`
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;

  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
`

const RadioControl = styled.div`
  position: relative;

  display: block;
  flex-shrink: 0;

  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;

  background: 1px solid ${({ theme }) => theme.colors.background.default};
  border: 1px solid ${({ theme }) => theme.colors.background.subtle};

  input ~ & {
    transition: box-shadow ${({ theme }) => theme.transitions.ease('250ms')};
  }

  input:focus ~ & {
    box-shadow: 0 0 0 3px
      ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 30)};
  }

  input:checked ~ & {
    background: ${({ theme }) => theme.colors.foreground.default};
    border: 1px solid ${({ theme }) => theme.colors.foreground.default};

    &:after {
      content: '';
      display: block;
      position: absolute;

      height: 0.25rem;
      width: 0.25rem;
      border-radius: 100%;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: ${({ theme }) => theme.colors.background.default};
    }
  }

  input:disabled ~ & {
    background: ${({ theme }) =>
      addAlphaToColor(theme.colors.background.subtle)};
  }
`

const TextLabel = styled(Text)`
  margin-left: 0.5rem;

  input:disabled ~ & {
    opacity: 0.75;
  }
`
