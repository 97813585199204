import { Fragment } from 'react'

import {
  MultilinkStoryblok,
  ServiceStoryblok,
  TextLinkStoryblok,
} from 'common/types'
import { storyblok } from 'lib/storyblok'
import { StoryblokRichtext } from 'lib/types'

import { removeLeadingAndTrailingSlash } from './string'

export const textByLine = (
  string: string,
  renderLine: (line: string, index: number, totalLength: number) => JSX.Element
): JSX.Element[] => {
  const split = string.split('\n')
  return split.map((line, i) => (
    <Fragment key={i}>{renderLine(line, i, split.length)}</Fragment>
  ))
}

export const hasValidLink = (link?: TextLinkStoryblok['link']): boolean => {
  if (!link) {
    return false
  }

  if (link.linktype === 'story' || link.linktype === 'url') {
    return !!link.cached_url
  }

  if (link.linktype === 'email') {
    return !!link.email
  }

  return false
}

export const getStartsWith = (
  lang: string,
  startsWithFromCMS?: string
): string => {
  if (!startsWithFromCMS) {
    return `${lang}/`
  }

  return `${lang}/${removeLeadingAndTrailingSlash(startsWithFromCMS)}/`
}

export const storyblokImageService = (src: string, option?: string): string => {
  if (!option || !src) return src

  const pathAppend = '/m/'

  return src + pathAppend + option
}

/**
 * Use storyblok image service to transform the image as per
 * https://www.storyblok.com/docs/image-service
 *
 * Examples: '500x500', '600x130/smart', 'filters:quality(50)'
 */
type ImageAttributes = {
  src: string
  alt: string
  width: number
  height: number
}
export const getImageAttributes = (
  image?: { filename?: string; alt?: string | null; name?: string | null },
  transformOptions = 'filters:format(webp)'
): ImageAttributes => {
  const width = Number(image?.filename?.split('/')[5]?.split('x')[0]) || 0
  const height = Number(image?.filename?.split('/')[5]?.split('x')[1]) || 0
  const alt = image?.alt || image?.name || ''

  return {
    src: storyblokImageService(image?.filename || '', transformOptions),
    alt,
    width,
    height,
  }
}

export const richtextToString = (richtext: StoryblokRichtext): string => {
  return storyblok.richTextResolver
    .render(richtext)
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/&#(\d+);/g, function (_match: string, dec: number) {
      return String.fromCharCode(dec)
    })
}
// Convert degrees to radians
export const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180)
}

/**
 * Get distance between two locations in meters
 * @param {number} lat1 - Latitude of the first location.
 * @param {number} lon1 - Longitude of the first location.
 * @param {number} lat2 - Latitude of the second location.
 * @param {number} lon2 - Longitude of the second location.
 * @returns {number} Distance in meters.
 */
export const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const earthRadius = 6371000 // Radius of the earth in meters
  const dLat = deg2rad(lat2 - lat1) // Diference of latitude in radians
  const dLon = deg2rad(lon2 - lon1) // Diference of longitude in radians

  // Haversine formula
  const distanceBetweenPoints =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  // Angular distance
  const angularDistance =
    2 *
    Math.atan2(
      Math.sqrt(distanceBetweenPoints),
      Math.sqrt(1 - distanceBetweenPoints)
    )

  const distance = earthRadius * angularDistance // Distance in meters

  return distance
}

/**
 * Get distance between clinic and user location or mapbox searched location
 * @param {object} userLocation - User location object.
 * @param {object} mapboxSearchedLocation - Mapbox searched location object.
 * @param {array} clinicCoordinates - Clinic coordinates.
 * @returns {number} Distance in meters.
 */
export const getClinicDistance = ({
  userLocation,
  mapboxSearchedLocation,
  clinicCoordinates,
}: {
  userLocation?: { lat: number; lng: number } | null
  mapboxSearchedLocation?: {
    coordinates: { latitude: number; longitude: number }
  } | null
  clinicCoordinates: string[]
}): number | null => {
  if (userLocation) {
    return getDistance(
      Number(clinicCoordinates[0]),
      Number(clinicCoordinates[1]),
      userLocation.lat,
      userLocation.lng
    )
  }

  if (mapboxSearchedLocation) {
    return getDistance(
      Number(clinicCoordinates[0]),
      Number(clinicCoordinates[1]),
      mapboxSearchedLocation.coordinates.latitude,
      mapboxSearchedLocation.coordinates.longitude
    )
  }

  return null
}

export const getStoryblokCacheValue = (isPreview = false) => {
  if (isPreview) return Date.now()

  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)
  now.setMinutes(0)
  return now.getTime()
}

export function getAnchorFromCmsLink(link: MultilinkStoryblok | undefined) {
  const attributes: {
    href?: string
    rel?: string
    target?: '_blank'
  } = {}

  switch (link?.linktype) {
    case 'story': {
      if ('story' in link && link.story && link.story.full_slug) {
        attributes.href = `/${removeLeadingAndTrailingSlash(
          link.story.full_slug
        )}`
      } else if (link.cached_url) {
        attributes.href = `/${removeLeadingAndTrailingSlash(link.cached_url)}`
      }
      break
    }
    case 'email': {
      attributes.href = `mailto:${link.email}`
      break
    }
    default: {
      const href = link?.url || link?.cached_url || undefined
      attributes.href = href

      if (href && href.startsWith('http')) {
        attributes.target = '_blank'
        attributes.rel = 'noopener noreferrer'
      }

      break
    }
  }

  return attributes
}

export function sortServicesTypesAlphabetically(
  arr: ServiceStoryblok['type'][]
) {
  return arr.sort((a, b) => a.localeCompare(b))
}
