import { HTMLAttributes, useEffect, useState } from 'react'
import styled from 'styled-components'

import { copyStringToClipboard } from 'common/utils/copyToClipboard'
import { useTranslation } from 'lib/i18n'

export const SocialShare = (
  props: HTMLAttributes<HTMLUListElement>
): JSX.Element => {
  const { i18n } = useTranslation()
  const [fullUrl, setFullUrl] = useState<string>('')
  const copyLinkText = i18n('copyLink')
  const [copyImage, setCopyImage] = useState('/assets/anchor.png')

  useEffect(
    function setLocationHref() {
      setFullUrl(window.location.href)
    },
    [setFullUrl]
  )

  useEffect(
    function handleTimeoutCopyUrlImage() {
      let timeout: NodeJS.Timeout
      if (copyImage === '/assets/anchor-copied.png') {
        timeout = setTimeout(() => setCopyImage('/assets/anchor.png'), 2000)
      }
      return () => {
        if (timeout) clearTimeout(timeout)
      }
    },
    [copyImage]
  )

  const handleCopy = () => {
    setCopyImage('/assets/anchor-copied.png')
    copyStringToClipboard(fullUrl)
  }

  return (
    <SocialItems {...props}>
      <SocialItem>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${fullUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Facebook"
            src="/assets/facebook-default.png"
            width={32}
            height={32}
          />
        </a>
      </SocialItem>
      <SocialItem>
        <a
          href={`https://twitter.com/intent/tweet?url=${fullUrl}&text=${i18n(
            'iLikedThis'
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Twitter"
            src="/assets/twitter-default.png"
            width={32}
            height={32}
          />
        </a>
      </SocialItem>
      <SocialItem>
        <button title={copyLinkText} onClick={handleCopy}>
          <img alt="Anchor" src={copyImage} width={32} height={32} />
        </button>
      </SocialItem>
    </SocialItems>
  )
}

const SocialItems = styled.ul`
  display: flex;
`

const SocialItem = styled.li`
  cursor: pointer;
  &:not(:last-of-type) {
    margin-right: 0.75rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    height: 2rem;
  }
`
