import { useRef, useState } from 'react'

import useOutsideClick from 'common/hooks/useOutsideClick'

import { Button, ButtonProps } from './Button'
import { Menu, MenuProps } from './Menu'
import { Popover } from './Popover'

export type PopoverMenuProps = {
  // The items for the menu
  menu: MenuProps['menu']
  // Function that triggers anytime an item is selected on the menu
  onSelect?: MenuProps['onSelect']
  // should it close on Select?
  closeOnSelect?: boolean
  // Footer for Menu
  footer?: MenuProps['footer']
  // The text for the button
  label: string
  // is the button menu loading?
  isLoading?: boolean
  // is the button menu full?
  isFull?: boolean
  // an icon on the left of the menu (right is always chevron)
  leftIcon?: ButtonProps['leftIcon']
  // button variant
  variant?: ButtonProps['variant']
  // button size
  size?: ButtonProps['size']
}

export const PopoverMenu = (props: PopoverMenuProps): JSX.Element => {
  const {
    menu,
    onSelect,
    closeOnSelect,
    footer,
    label,
    isLoading,
    isFull,
    leftIcon,
    variant,
    size,
  } = props
  const wrapperRef = useRef<HTMLSpanElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null)
  useOutsideClick(wrapperRef, () => {
    setIsOpen(false)
  })

  const handleSelect = (item: MenuProps['menu'][0]['items'][0]) => {
    onSelect?.(item)
    if (closeOnSelect) setIsOpen(false)
  }

  return (
    <span ref={wrapperRef}>
      <Button
        ref={setReferenceElement}
        rightIcon="caret-down"
        leftIcon={leftIcon}
        variant={variant}
        isFull={isFull}
        isLoading={isLoading}
        size={size}
        onClick={() => {
          setIsOpen((state) => !state)
        }}
      >
        {label}
      </Button>
      <Popover
        referenceElement={referenceElement}
        isOpen={isOpen}
        placement="bottom-start"
        offset={[0, 16]}
      >
        <Menu menu={menu} onSelect={handleSelect} footer={footer} />
      </Popover>
    </span>
  )
}
