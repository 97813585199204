import { AnchorHTMLAttributes, forwardRef, Ref } from 'react'
import styled from 'styled-components'

import { IconOptions, Icon } from './Icon'
import { IconHolder } from './IconHolder'

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  leftIcon?: IconOptions
  rightIcon?: IconOptions
  isUnderlined?: boolean
}
export const Link = forwardRef(function Link(
  { leftIcon, rightIcon, isUnderlined, children, ...props }: LinkProps,
  ref: Ref<HTMLAnchorElement>
): JSX.Element {
  return (
    <StyledLink isUnderlined={isUnderlined} ref={ref} {...props}>
      {leftIcon && (
        <IconHolder css={{ marginRight: children ? '0.5rem' : 0 }}>
          <Icon icon={leftIcon} />
        </IconHolder>
      )}
      {children}
      {rightIcon && (
        <IconHolder css={{ marginLeft: children || leftIcon ? '0.5rem' : 0 }}>
          <Icon icon={rightIcon} />
        </IconHolder>
      )}
    </StyledLink>
  )
})

const StyledLink = styled.a<{ isUnderlined?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;

  color: inherit;
  text-decoration: ${({ isUnderlined }) =>
    isUnderlined ? 'underline' : 'none'};

  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};

  @media (hover: hover) {
    &:hover {
      opacity: 0.9;
    }
  }
`
