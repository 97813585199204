import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

// drawer state manage
export const useDrawer = () => {
  const router = useRouter()
  const [drawer, setDrawer] = useState<string | null>(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    setDrawer(searchParams.get('drawer') || null)
  }, [router.asPath])

  // updates url with ?drawer and keeps the searchParams
  const open = (slug: string) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.searchParams)
    searchParams.set('drawer', slug)

    router.push(`${url.pathname}?${searchParams.toString()}`, undefined, {
      shallow: true,
    })
  }

  // cleans url on close drawer
  const close = () => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.searchParams)
    searchParams.delete('drawer')

    router.back()
  }

  return {
    drawer,
    open,
    close,
  }
}

export default useDrawer
