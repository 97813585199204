import NextImage from 'next/image'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { Container, Tag, Text } from 'common/UI'
import useDrawer from 'common/hooks/useDrawer'
import { TeamMemberDetailPageStoryblok } from 'common/types'
import { getImageAttributes, textByLine } from 'common/utils/content'

type Props = {
  story: ISbStoryData<TeamMemberDetailPageStoryblok>
}

export const TeamMemberDetailPageHeader = ({ story }: Props): JSX.Element => {
  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  return (
    <Container variant={isDrawer ? 'full' : 'typography'}>
      <Wrapper>
        <InnerWraper>
          <div>
            {story.name && (
              <Text
                as="h1"
                variant="title/large"
                css={{ marginBottom: '1.25rem' }}
              >
                {story.name}
              </Text>
            )}
            {story.content.role_detail && (
              <div>
                {textByLine(story.content.role_detail, (part, i) => (
                  <Text
                    as="p"
                    variant="twenty"
                    css={{ marginTop: i !== 0 ? '0.625rem' : '0' }}
                  >
                    {part}
                  </Text>
                ))}
              </div>
            )}
          </div>
          <div>
            {story.content.speciality_title && (
              <Text
                as="p"
                variant="eighteen/regular"
                css={{ opacity: '60%', margin: '.5rem 0rem' }}
              >
                {story.content.speciality_title}
              </Text>
            )}
            {story.content.specialties &&
              story.content.specialties?.length > 0 && (
                <div css={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}>
                  {story.content.specialties.map((item) => {
                    return <Tag key={item._uid} label={item.speciality} />
                  })}
                </div>
              )}
          </div>
        </InnerWraper>
        {story.content.image?.filename && (
          <ImageWrapper>
            <NextImage
              {...getImageAttributes(story.content.image)}
              style={{ objectFit: 'cover', height: '100%' }}
            />
          </ImageWrapper>
        )}
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem 0rem 2rem 0rem;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ImageWrapper = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  height: 20.75rem;
  width: 100%;

  ${({ theme }) => theme.media.lg} {
    width: 17.0625rem;
  }
`

const InnerWraper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 21.5625rem;
  justify-content: space-between;
`
