export const convertDistance = (
  distanceInMeters: number,
  region: string | null
) => {
  if (region?.toLowerCase() === 'gb') {
    return `${(distanceInMeters * 0.0006213712).toFixed(2)} mi`
  }

  return `${(distanceInMeters / 1000).toFixed(2)} km`
}
