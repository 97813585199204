import { useEffect } from 'react'

export const useBodyLock = (condition: boolean): void => {
  useEffect(() => {
    document.body.style.overflow = condition ? 'hidden' : 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [condition])
}
