import { VirtualElement } from '@popperjs/core'
import styled from 'styled-components'

import { Popover } from 'common/UI/Popover'
import { Text } from 'common/UI/Text'

type TooltipProps = {
  referenceElement: Element | VirtualElement | null | undefined
  isOpen: boolean
  label: string
}

export const Tooltip = ({
  referenceElement,
  isOpen,
  label,
  ...props
}: TooltipProps) => {
  return (
    <Popover
      referenceElement={referenceElement}
      isOpen={isOpen}
      placement="top"
      offset={[0, 10]}
    >
      <Wrapper {...props}>
        <Text as="p" variant="fourteen">
          {label}
        </Text>
      </Wrapper>
    </Popover>
  )
}

const Wrapper = styled.div`
  padding: 0.5rem 0.75rem;
  color: ${({ theme }) => theme.colors.palette.white};
  background: ${({ theme }) => theme.colors.palette.blue[900]};
  border-radius: 0.5rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;

    height: 10px;
    width: 10px;
    border-bottom-right-radius: 2px;

    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);

    background: ${({ theme }) => theme.colors.palette.blue[900]};
  }
`
