import { LogoJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'

import { SITE_ORIGIN } from 'common/constants'
import {
  removeLeadingAndTrailingSlash,
  removeTrailingSlash,
} from 'common/utils/string'
import { useDataContext } from 'lib/dataContext'

import { DesktopTopNav } from './DesktopTopNav'
import { Flare } from './Flare'
import { MobileNavBar } from './MobileTopNav'
import { SearchPanel } from './SearchPanel'

export const TopNav = (): JSX.Element => {
  const [isSearchVisible, setSearchVisible] = useState<boolean>(false)
  const { config } = useDataContext()
  const {
    asPath,
    query: { lang },
  } = useRouter()

  if (!config || !config?.content) {
    return <></>
  }

  const { logo_link, flare } = config.content

  const getHomelink = (): string | null => {
    if (logo_link && logo_link.linktype === 'story' && logo_link?.story) {
      return `/${removeLeadingAndTrailingSlash(logo_link.story.full_slug)}`
    }

    return null
  }

  const homeUrl = getHomelink() || `/${lang}`

  const isChildPage = homeUrl !== asPath

  return (
    <>
      {config?.content.menu_logo && config.content.menu_logo.filename && (
        <LogoJsonLd
          logo={config.content.menu_logo.filename}
          url={`${removeTrailingSlash(SITE_ORIGIN)}${homeUrl}`}
        />
      )}
      {config && config.content?.menu_links && (
        <TopNavWrapper>
          <StyleDesktop>
            <DesktopTopNav
              homeUrl={homeUrl}
              onSearchClick={() => setSearchVisible(true)}
            />
            {flare && !isChildPage && <Flare flare={flare} />}
          </StyleDesktop>
          <StyleMobileNavBar>
            <MobileNavBar
              homeUrl={homeUrl}
              onSearchClick={() => setSearchVisible(true)}
            />
            {flare && !isChildPage && <Flare flare={flare} isMobile />}
          </StyleMobileNavBar>
        </TopNavWrapper>
      )}
      <SearchPanel
        isVisible={isSearchVisible}
        onClose={() => setSearchVisible(false)}
      />
    </>
  )
}

const StyleMobileNavBar = styled.div`
  display: block;

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`

const StyleDesktop = styled.div`
  display: none;
  ${({ theme }) => theme.media.md} {
    display: block;
  }
`

const TopNavWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.high};
`
