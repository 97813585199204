import styled from 'styled-components'

import { addAlphaToColor, dashedLine } from 'common/utils/style'

export const Table = styled.table`
  font-size: 1rem;
  line-height: 1.25;
  border-collapse: collapse;
  width: 100%;
`

export const Thead = styled.thead<{ variant: string }>`
  font-size: ${({ variant }) =>
    variant === 'normal' ? '0.75rem' : '1.125rem'};
  line-height: 1.2;
  text-transform: ${({ variant }) => (variant === 'normal' ? 'uppercase' : '')};
  letter-spacing: 0.1ch;
  font-weight: ${({ variant }) => (variant === 'normal' ? '400' : '300')};

  background-color: ${({ theme, variant }) =>
    addAlphaToColor(
      theme.colors.accent.default,
      variant === 'normal' ? 20 : 0
    )};
`

export const Tbody = styled.tbody<{ variant: string }>`
  font-size: ${({ variant }) => (variant === 'normal' ? '1rem' : '1.125rem')};
  tr {
    @media (hover: hover) {
      &:hover {
        background-color: ${({ theme, variant }) =>
          variant === 'normal'
            ? addAlphaToColor(theme.colors.background.subtle, 20)
            : 'unset'};
      }
    }
  }
`

export const Tr = styled.tr<{ hasLineBottom: boolean }>`
  ${({ theme, hasLineBottom }) =>
    hasLineBottom &&
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.default, 60))};

  &:nth-child(even) {
    background-color: ${({ theme }) =>
      addAlphaToColor(theme.colors.background.subtle, 10)};
  }
`

export const Th = styled.th<{ variant: string }>`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  :first-child {
    padding-right: 1.25rem;
  }

  font-weight: ${({ variant }) => (variant === 'normal' ? '400' : '300')};

  color: ${({ theme, variant }) =>
    variant === 'wide'
      ? addAlphaToColor(theme.colors.foreground.default, 80)
      : theme.colors.foreground.default};

  white-space: ${({ variant }) => (variant === 'normal' ? 'nowrap' : 'unset')};
`

export const Td = styled.td<{ variant: string }>`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  :first-child {
    padding-right: 1.25rem;
  }
`
