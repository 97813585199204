import NextImage from 'next/image'
import Link from 'next/link'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { Text } from 'common/UI'
import { PatientStoryStoryblok } from 'common/types'
import {
  getImageAttributes,
  sortServicesTypesAlphabetically,
} from 'common/utils/content'
import { formatDateLocale } from 'common/utils/datetime'
import { removeTrailingSlash } from 'common/utils/string'
import { useTranslation } from 'lib/i18n'

import { TagList } from '../TagList'

type Props = {
  item: ISbStoryData<PatientStoryStoryblok>
}

export const PatientStoryThumb = ({ item, ...props }: Props): JSX.Element => {
  const { locale } = useTranslation()

  const categories = Array.from(
    new Set(item.content.categories?.map((cat) => cat.content?.type) || [])
  )

  return (
    <Thumb href={removeTrailingSlash(`/${item.full_slug}`)} {...props}>
      {item.content.thumb_image && item.content.thumb_image.filename && (
        <ImageHolder>
          <NextImage
            fill
            sizes="500px"
            alt={getImageAttributes(item.content.thumb_image).alt}
            src={
              getImageAttributes(
                item.content.thumb_image,
                '500x500/smart/filters:format(webp)'
              ).src
            }
            style={{ objectFit: 'cover', height: '100%' }}
          />
        </ImageHolder>
      )}
      <Content>
        <Meta>
          {categories?.length > 0 && (
            <TagList
              hasHiddenTags
              hasColorVariants
              maxVisibleTags={1}
              tags={sortServicesTypesAlphabetically(categories)}
            />
          )}

          <Text as="span" variant="sixteen">
            {item.first_published_at
              ? formatDateLocale(item.first_published_at, locale)
              : ''}
          </Text>
        </Meta>
        <Text
          as="h2"
          variant="title/small/regular"
          css={{ marginTop: '0.75rem' }}
        >
          {item.content.title}
        </Text>
      </Content>
    </Thumb>
  )
}

export const Thumb = styled(Link)`
  color: inherit;
`

const ImageHolder = styled.div`
  position: relative;
  padding-bottom: 63%;
  border-radius: 1rem;
  overflow: hidden;

  ${({ theme }) => theme.media.md} {
    padding-bottom: 75%;
  }
`

const Content = styled.div`
  padding: 1.25rem 0 0 0;
`

const Meta = styled.div`
  display: flex;
  align-items: center;
`
