import { motion, AnimatePresence } from 'framer-motion'
import styled, { css } from 'styled-components'

import { useBodyLock } from 'common/hooks/useBodyLock'

import { Portal } from './Portal'

type ModalPosition =
  | 'top-left'
  | 'left'
  | 'bottom-left'
  | 'top'
  | 'bottom'
  | 'top-right'
  | 'right'
  | 'bottom-right'
  | 'center'

export type ModalProps = {
  isVisible: boolean
  modalPosition?: ModalPosition
  onClose?: () => void
  children?: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isVisible,
  onClose,
  modalPosition = 'center',
}) => {
  useBodyLock(isVisible)

  return (
    <AnimatePresence>
      {isVisible && (
        <Portal>
          <Overlay
            modalPosition={modalPosition}
            onMouseDown={onClose}
            onTouchStart={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 0.1 }}
          >
            <Holder
              onMouseDown={(e) => {
                e.stopPropagation()
              }}
              onTouchStart={(e) => {
                e.stopPropagation()
              }}
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              exit={{ y: 20 }}
            >
              {children}
            </Holder>
          </Overlay>
        </Portal>
      )}
    </AnimatePresence>
  )
}

const getModalPosition = ({
  modalPosition,
}: {
  modalPosition: ModalPosition
}) => {
  switch (modalPosition) {
    case 'center':
      return css`
        justify-content: center;
        align-items: center;
      `
    case 'top':
      return css`
        justify-content: center;
        align-items: flex-start;
      `
    case 'top-right':
      return css`
        justify-content: flex-end;
        align-items: flex-start;
      `
    case 'right':
      return css`
        justify-content: flex-end;
        align-items: center;
      `
    case 'bottom-right':
      return css`
        justify-content: flex-end;
        align-items: flex-end;
      `
    case 'bottom':
      return css`
        justify-content: center;
        align-items: flex-end;
      `
    case 'bottom-left':
      return css`
        justify-content: flex-start;
        align-items: flex-end;
      `
    case 'left':
      return css`
        justify-content: flex-start;
        align-items: center;
      `
    case 'top-left':
      return css`
        justify-content: flex-start;
        align-items: flex-start;
      `
    default:
      throw new Error('Unexpected position received')
  }
}

const Overlay = styled(motion.div)<{ modalPosition: ModalPosition }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 99999;

  background-color: rgba(0, 0, 0, 0.3);

  overflow-y: auto;

  /* Position */
  display: flex;

  ${getModalPosition};
`

const Holder = styled(motion.div)`
  margin: 1rem;
  cursor: default;
`
