// only accepts array of objects with string values for now
export const groupArrayBy = <T extends Record<string, string>>(
  arr: T[],
  key: string,
  transformValue: (value: string) => string = (value) => value
): Record<string, T[]> => {
  return arr.reduce<Record<string, T[]>>((acc, item) => {
    const itemValue = transformValue(item[key])
    const prev = acc[itemValue] || []

    return {
      ...acc,
      [itemValue]: [...prev, item],
    }
  }, {})
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This function will validate any type of array
export const isArrayNotEmpty = (arr: Array<any>): boolean => {
  return Array.isArray(arr) && arr.length > 0
}

export const range = (start: number, end: number): number[] => {
  const arr: number[] = []
  for (let i = start; i < end; i++) {
    arr.push(i)
  }
  return arr
}
