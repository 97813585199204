import ReactContentLoader, { IContentLoaderProps } from 'react-content-loader'
import { useTheme } from 'styled-components'

import { addAlphaToColor } from 'common/utils/style'

export const ContentLoader = (props: IContentLoaderProps): JSX.Element => {
  const theme = useTheme()

  return (
    <ReactContentLoader
      backgroundColor={addAlphaToColor(theme.colors.foreground.subtle, 20)}
      foregroundColor={addAlphaToColor(theme.colors.foreground.subtle, 10)}
      opacity={0.25}
      {...props}
    />
  )
}
