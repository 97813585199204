import { ImgHTMLAttributes } from 'react'

import { getImageAttributes } from 'common/utils/content'
import { StoryblokImage } from 'lib/types'

type ObjectPosition = 'top' | 'bottom' | 'center' | 'left' | 'right'

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  image: StoryblokImage
  transformOption?: string
  objectposition?: ObjectPosition
}

export const CMSImage = ({
  image,
  transformOption,
  ...props
}: Props): JSX.Element => {
  const { src, alt } = getImageAttributes(image, transformOption)
  return <img src={src} alt={alt} {...props} />
}
