import { VirtualElement, Options } from '@popperjs/core'
import { useMemo, useState } from 'react'
import { Modifier, usePopper } from 'react-popper'
import { useTheme } from 'styled-components'

export type PopoverProps = {
  referenceElement: Element | VirtualElement | null | undefined
  isOpen: boolean
  placement?: Options['placement']
  offset?: [xOffset: number, yOffset: number]
  modifiers?: Options['modifiers']
  children: React.ReactNode
}

export const Popover: React.FC<PopoverProps> = ({
  referenceElement,
  isOpen,
  placement = 'bottom',
  offset = [0, 16],
  modifiers = [],
  children,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const theme = useTheme()

  const offsetModifier: Modifier<'offset'> = useMemo(() => {
    return {
      name: 'offset',
      enabled: true,
      options: {
        offset,
      },
    }
  }, [offset])

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [offsetModifier, ...modifiers],
    placement,
  })

  if (!isOpen) {
    return null
  }

  return (
    <div
      ref={setPopperElement}
      style={{ ...styles.popper, zIndex: theme.zIndex.highest }}
      {...attributes.popper}
    >
      {children}
    </div>
  )
}
