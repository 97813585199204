import { ISbStoryData } from 'storyblok-js-client'

import { TeamMemberDetailPageStoryblok } from 'common/types'
import { DynamicBlock } from 'modules/Blocks/DynamicBlock'
import { Layout } from 'modules/Layout/Layout'
import { Seo } from 'modules/shared/Seo'

import { TeamMemberDetailPageHeader } from './TeamMemberDetailPageHeader'

type Props = {
  story: ISbStoryData<TeamMemberDetailPageStoryblok>
}

export const TeamMemberDetailPage = ({ story }: Props): JSX.Element => {
  return (
    <Layout>
      <Seo story={story} />
      {story.content && <TeamMemberDetailPageHeader story={story} />}
      {story.content.body &&
        story.content.body.map((block) => (
          <DynamicBlock key={block._uid} block={block} />
        ))}
    </Layout>
  )
}
