import React, { useState } from 'react'
import styled from 'styled-components'

import { Tag, Tooltip } from 'common/UI'
import { theme } from 'common/theme'

interface TagListProps {
  tags: string[]
  maxVisibleTags?: number
  hasColorVariants?: boolean
  hasHiddenTags?: boolean
}

const categoriesColors = (type: string) => {
  switch (type) {
    case 'diagnosis':
      return 'palette.yellow.default'
    case 'treatment':
      return 'palette.pink.default'
    case 'donation':
      return 'palette.orange.default'
    case 'lgbtqia+':
      return 'palette.hyacinth.default'
    case 'preservation':
      return 'palette.aqua.dark'
    case 'condition':
      return 'palette.violet.wild'
    case 'advice':
      return 'palette.green.default'
    case 'news':
      return 'palette.lime.default'
    default:
      return 'palette.blue.200'
  }
}

export const TagList: React.FC<TagListProps> = ({
  tags,
  maxVisibleTags = 1,
  hasColorVariants = false,
  hasHiddenTags = false,
}) => {
  const [isTooltipOpen, setTooltipIsOpen] = useState(false)
  const [tagsListRef, setTagsListRef] = useState<HTMLElement | null>(null)

  if (!tags || tags.length === 0) return null

  const visibleTags = tags.slice(0, maxVisibleTags)
  const hiddenTags = tags.slice(maxVisibleTags)
  const hiddenTagsString = hiddenTags
    .map((tag) =>
      tag === 'lgbtqia+'
        ? tag.toUpperCase()
        : tag.charAt(0).toUpperCase() + tag.slice(1)
    )
    .join(', ')

  return (
    <TagsWrapper>
      {visibleTags.map((tag, index) => (
        <Tag
          key={index}
          label={tag}
          color={hasColorVariants ? categoriesColors(tag) : 'palette.blue.200'}
          css={{
            marginRight: '.75rem',
            textTransform: tag === 'lgbtqia+' ? 'uppercase' : 'capitalize',
          }}
        />
      ))}

      {hasHiddenTags && hiddenTags.length > 0 && (
        <div ref={setTagsListRef} css={{ zIndex: theme.zIndex.mid }}>
          <Tag
            onMouseEnter={() => setTooltipIsOpen(true)}
            onMouseLeave={() => setTooltipIsOpen(false)}
            label={`+ ${hiddenTags.length}`}
            css={{
              marginRight: '.75rem',
              cursor: 'pointer',
              backgroundColor: 'default',
            }}
          />

          <Tooltip
            referenceElement={tagsListRef}
            isOpen={isTooltipOpen}
            label={hiddenTagsString}
          />
        </div>
      )}
    </TagsWrapper>
  )
}

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
