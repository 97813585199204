import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { Button, Text, Theme } from 'common/UI'
import { AlertStoryblok } from 'common/types'
import { hasValidLink } from 'common/utils/content'
import { useDataContext } from 'lib/dataContext'
import { getLocalStorage, setLocalStorage } from 'lib/storage'
import { CMSLink } from 'modules/shared'

export const Alert = (): JSX.Element | null => {
  const { alert } = useDataContext()

  if (!alert || !alert.content.text) {
    return null
  }

  return <AlertInner alert={alert} />
}

const AlertInner = ({ alert }: { alert: ISbStoryData<AlertStoryblok> }) => {
  const [shouldShow, setShouldShow] = useState(false)
  const alertHeight = alert.content.height || 55

  useEffect(
    function checkSessionStorage() {
      let timeout: NodeJS.Timeout
      const itemInStorage = getLocalStorage(alert.uuid)

      if (!itemInStorage) {
        timeout = setTimeout(() => setShouldShow(true), 1000)
      }

      return () => {
        if (timeout) clearTimeout(timeout)
      }
    },
    [alert]
  )

  const dismiss = () => {
    setShouldShow(false)
    setLocalStorage(alert.uuid, '1')
  }

  return (
    <AnimatePresence>
      {shouldShow && (
        <motion.div
          style={{ position: 'relative' }}
          initial={{ height: 0, y: -20, opacity: 0 }}
          animate={{ height: `${alertHeight}px`, y: 0, opacity: 1 }}
          exit={{ height: 0, y: 0, opacity: 0, transition: { duration: 0.05 } }}
        >
          <Theme colorTheme="light-orange">
            <AlertHolder alertColor={alert.content.color}>
              <div>
                <Text as="p">{alert.content.text}</Text>
                {alert.content.link &&
                  alert.content.link_text &&
                  hasValidLink(alert.content.link) && (
                    <>
                      {' '}
                      <CMSLink
                        linkStyle="text"
                        rightIcon="arrow-right"
                        href={alert.content.link}
                        onClick={() => dismiss()}
                      >
                        {alert.content.link_text}
                      </CMSLink>
                    </>
                  )}
              </div>
              <CloseHolder>
                <Button
                  variant="text"
                  size="small"
                  leftIcon="close"
                  onClick={dismiss}
                />
              </CloseHolder>
            </AlertHolder>
          </Theme>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const AlertHolder = styled.div<{ alertColor: 'orange' | 'blue' | undefined }>`
  position: absolute;

  z-index: ${({ theme }) => theme.zIndex.mid};

  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  border-radius: 0.75rem;

  padding: 0.75rem;
  padding-right: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: ${({ theme }) => theme.shadows.subtle};

  background: ${({ theme, alertColor }) =>
    alertColor === 'orange' ? '#FCE5DD' : theme.colors.palette.brand[200]};

  p {
    display: inline;
  }

  a,
  svg {
    color: ${({ theme, alertColor }) =>
      alertColor === 'orange'
        ? theme.colors.palette.purple.light
        : theme.colors.palette.brand.default};
  }
`

const CloseHolder = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`
