import { useEffect } from 'react'

type Refs = { current: Node | null } | Array<{ current: Node | null }>
type Handler = (e: MouseEvent | TouchEvent) => void

const useOutsideClick = (ref: Refs, handler: Handler): void => {
  useEffect(
    function handleAddEventListener() {
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (Array.isArray(ref)) {
          if (
            ref.some(
              (r) => r.current && r.current.contains(event.target as Node)
            )
          ) {
            return
          }
        } else {
          if (ref.current && ref.current.contains(event.target as Node)) {
            return
          }
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler]
  )
}

export default useOutsideClick
