export const capitalizeFirstLetter = (text?: string): string => {
  if (typeof text !== 'string') return ''
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const removeLeadingAndTrailingSlash = (text: string): string => {
  return text?.replace(/^\/{0,}|\/{0,}$/g, '') || ''
}

export const removeTrailingSlash = (text: string): string => {
  return text.replace(/\/$/g, '')
}

export const toCapitalize = (string: string, lower = false): string =>
  (lower ? string.toLowerCase() : string).replace(
    /(?:^|\s|["'([{])+\S/g,
    (match) => match.toUpperCase()
  )

export const findAndSplit = (term: string, string: string): Array<string> => {
  return string?.split(new RegExp('(' + term + ')', 'i'))
}

export const isValidString = (str: unknown): str is string => {
  return typeof str === 'string'
}

export const toArray = (inputString: string) => {
  // Use the split method to split the string by commas
  // Trim each element to remove leading and trailing whitespaces
  return inputString.split(',').map((item) => item.trim())
}

export const slugify = (text: string): string => {
  // Normalize the text to remove accents
  const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  // Remove all non-alphanumeric characters and replace spaces with hyphens
  const slug = normalizedText
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s-]/g, '') // Remove non-alphanumeric characters (except spaces and hyphens)
    .trim() // Trim whitespace from both ends
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen

  return slug
}
