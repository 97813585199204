import { HTMLAttributes } from 'react'
import styled, { CSSProperties } from 'styled-components'

type IconHolderProps = HTMLAttributes<HTMLDivElement> & {
  size?: string
  css?: CSSProperties
}

export const IconHolder: React.FC<IconHolderProps> = ({
  size = '1.25rem',
  css,
  ...props
}) => {
  return <Holder size={size} {...props} style={css} />
}

const Holder = styled.div<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`
