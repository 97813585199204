import { motion } from 'framer-motion'
import Router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, Icon, Text, Theme } from 'common/UI'
import { copyStringToClipboard } from 'common/utils/copyToClipboard'
import { addAlphaToColor } from 'common/utils/style'
import { useTranslation } from 'lib/i18n'

export const PreviewAlert: React.FC = () => {
  const { i18n } = useTranslation()
  const [path, setPath] = useState<string | undefined>()
  const [origin, setOrigin] = useState<string | undefined>()
  const router = useRouter()

  useEffect(
    function updatePathOnRouteChange() {
      const handleRouteChange = () => {
        setPath(window.location.pathname)
        setOrigin(window.location.origin)
      }

      handleRouteChange()
      router.events.on('routeChangeComplete', handleRouteChange)

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    },
    [router.events]
  )

  const fullPath = path
    ? `${origin}/api/preview?token=${
        process.env.STORYBLOK_API_PREVIEW_KEY
      }&slug=${path.slice(1)}/`
    : '/'

  const handleCopy = () => {
    copyStringToClipboard(fullPath)
  }

  return (
    <Theme colorTheme="dark-blue">
      <Wrapper drag>
        <Holder>
          <Header>
            <Icon icon="world" />
            <Text as="h3" variant="twelve/caps">
              {i18n('previewEnabled.title')}
            </Text>
          </Header>
          {path && origin && process.env.STORYBLOK_API_PREVIEW_KEY && (
            <Input value={fullPath} disabled />
          )}
          <div>
            <Button
              isFull
              size="small"
              css={{ marginBottom: '0.5rem' }}
              onClick={handleCopy}
            >
              {i18n('copyLink')}
            </Button>
            <Button
              isFull
              variant="ghost"
              size="small"
              onClick={() => {
                Router.push(
                  path
                    ? `/api/exit-preview?backTo=${path}`
                    : '/api/exit-preview'
                )
              }}
            >
              {i18n('previewEnabled.exitPreview')}
            </Button>
          </div>
        </Holder>
      </Wrapper>
    </Theme>
  )
}

const Wrapper = styled(motion.div)`
  cursor: move;

  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 999999999999;
  width: 15rem;

  border-radius: 1.5rem;

  padding: 0.25rem;

  background-color: ${({ theme }) => theme.colors.background.default};
  box-shadow: ${({ theme }) => theme.shadows.high};
`

const Input = styled.input`
  appearance: none;
  border: 0;
  font-family: inherit;
  font-size: 0.8rem;

  margin-bottom: 0.5rem;
  padding: 0.325rem;
  width: 100%;

  border-radius: 0.25rem;

  color: ${({ theme }) => theme.colors.foreground.default};
  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 10)};

  ::selection {
    color: ${({ theme }) => theme.colors.background.default};
    background-color: ${({ theme }) => theme.colors.foreground.default};
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }
`

const Holder = styled.div`
  padding: 0.5rem;
`
