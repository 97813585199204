export const isValidLocale = (locale: string): boolean => {
  const twoLetterLocale = /^[a-z]{2}$/g
  const fourLetterLocale = /^[a-z]{2}-[a-z]{2}$/g

  const lowercaseLocale = locale.toLowerCase()

  return (
    twoLetterLocale.test(lowercaseLocale) ||
    fourLetterLocale.test(lowercaseLocale)
  )
}

export const upperCaseLocaleRegion = (locale: string): string => {
  if (/^[a-z]{2}-[a-z]{2}$/g.test(locale)) {
    return locale
      .split('-')
      .map((part, i) => (i > 0 ? part.toUpperCase() : part))
      .join('-')
  }

  return locale
}

const FALLBACK = 'en'

export const getOptimalDestination = ({
  countryCode,
  availableTargets,
  preferredLanguages,
}: {
  countryCode: string | undefined
  availableTargets: string[]
  preferredLanguages: string[]
}): string => {
  if (availableTargets.length === 0) return FALLBACK

  const langs = preferredLanguages.map((lang) => lang.split('-')[0])

  const countryTargets = availableTargets.filter((target) => {
    const [_language, region] = target.split('-')
    return region === (countryCode || '').toLowerCase()
  })

  const countryAndLangMatch = countryTargets.find((target) => {
    const [language] = target.split('-')
    return langs.includes(language)
  })

  // optimal match: country and language match for this user
  if (countryAndLangMatch) return countryAndLangMatch

  // sub-optimal match: country match, but no language match
  if (countryTargets.length > 0) {
    const enVersion = countryTargets.find((target) => {
      return target.split('-')[0] === 'en'
    })

    return enVersion || countryTargets[0]
  }

  // fallback: international version with language match
  const internationalTargets = availableTargets.filter((target) => {
    const [language, region] = target.split('-')
    return !region && langs.includes(language)
  })
  const bestInternationalVersion = internationalTargets.find((target) => {
    return target === langs[0]
  })

  return bestInternationalVersion || internationalTargets[0] || FALLBACK
}
