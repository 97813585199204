import { motion, AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { ISbStoryData } from 'storyblok-js-client'
import styled, { useTheme } from 'styled-components'

import { Button, ButtonLink, Icon, Link } from 'common/UI'
import { useBodyLock } from 'common/hooks/useBodyLock'
import useDrawer from 'common/hooks/useDrawer'
import {
  PageStoryblok,
  TeamMemberDetailPageStoryblok,
  OpenEveningStoryblok,
  SatelliteClinicPageStoryblok,
} from 'common/types'
import { getStoryblokCacheValue } from 'common/utils/content'
import { isMobile } from 'common/utils/isMobile'
import { addAlphaToColor } from 'common/utils/style'
import { getStory } from 'lib/api'
import { useDataContext } from 'lib/dataContext'
import { useTranslation } from 'lib/i18n'
import { Empty } from 'modules/AllEntries/Empty'
import { DynamicBlock } from 'modules/Blocks/DynamicBlock'
import { CMSLink } from 'modules/shared'
import { RESOLVE_RELATIONS } from 'pages/[lang]/[[...slug]]'

import { SatelliteClinicPageHeader } from './SatelliteClinicPageHeader'
import { TeamMemberDetailPageHeader } from './TeamMemberDetailPageHeader'

export const PageDrawer = () => {
  const { drawer, close } = useDrawer()
  useBodyLock(!!drawer)

  const { i18n } = useTranslation()
  const theme = useTheme()
  const isMobileDevice = isMobile()

  //Floating Button's
  const { floating } = useDataContext()
  const contactPage = floating?.content?.contact_us
  const phones = floating?.content?.phones
  const contactUsBtnLabel = floating?.content?.contact_us_btn

  const router = useRouter()
  const { isPreview } = router

  const [data, setData] = useState<ISbStoryData<
    | PageStoryblok
    | TeamMemberDetailPageStoryblok
    | OpenEveningStoryblok
    | SatelliteClinicPageStoryblok
  > | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)
    setError(false)

    if (drawer) {
      try {
        const story = (await getStory(drawer, {
          resolve_links: 'url',
          resolve_relations: RESOLVE_RELATIONS.join(','),
          version: isPreview ? 'draft' : 'published',
          cv: getStoryblokCacheValue(isPreview),
        })) as ISbStoryData<PageStoryblok>

        setData(story)
      } catch {
        setError(true)
      }
    }

    setLoading(false)
  }, [drawer])

  useEffect(() => {
    if (router.isReady && drawer) {
      fetch()
    }
  }, [fetch, router.isReady, drawer])

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        close()
      }
    }

    if (drawer) {
      document.addEventListener('keydown', handleEscape)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [drawer, close])

  const transition = {
    x: { type: 'spring', stiffness: 300, damping: 35 },
    opacity: { duration: 0.3 },
  }

  const paddingDrawer = !isMobileDevice ? 8 : 0

  return (
    <AnimatePresence>
      {drawer && (
        <>
          <Overlay
            onMouseDown={close}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ opacity: { duration: 0.3 } }}
          />
          <Wrapper
            initial={{
              x: '105%',
              right: paddingDrawer,
              top: paddingDrawer,
              bottom: paddingDrawer,
              opacity: 1,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: '105%', right: 0, opacity: 1 }}
            transition={transition}
          >
            <InnerWrapper>
              <Header>
                <Link leftIcon="expand" href={drawer}>
                  {i18n('expand')}
                </Link>
                <div
                  css={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                >
                  {floating && (
                    <ButtonsHolder>
                      {phones && (
                        <ButtonLink
                          href={`tel:${phones[0].phone}`}
                          variant="accent"
                          leftIcon="phone"
                          size="small"
                        >
                          {phones[0].phone}
                        </ButtonLink>
                      )}
                      {contactPage && (
                        <CMSLink
                          href={contactPage}
                          variant="secondary"
                          size="small"
                        >
                          {contactUsBtnLabel || i18n('floating.contactUs')}
                        </CMSLink>
                      )}
                    </ButtonsHolder>
                  )}
                  <Button
                    onClick={close}
                    size="small"
                    variant="ghost"
                    css={{ padding: '.625rem' }}
                  >
                    <Icon icon="close" />
                  </Button>
                </div>
              </Header>
              <ContentWrapper>
                {loading ? (
                  <ContentLoader
                    backgroundColor={addAlphaToColor(
                      theme.colors.foreground.subtle,
                      40
                    )}
                    foregroundColor={addAlphaToColor(
                      theme.colors.foreground.subtle,
                      10
                    )}
                    opacity={0.25}
                    viewBox="0 0 380 190"
                  >
                    <rect
                      x="22"
                      y="0"
                      rx="4"
                      ry="4"
                      width="90%"
                      height="100%"
                    />
                  </ContentLoader>
                ) : error ? (
                  <Empty isError={error} css={{ padding: '1.25rem 2rem' }} />
                ) : (
                  <>
                    {data?.content.component === 'team-member-detail-page' && (
                      <TeamMemberDetailPageHeader
                        story={
                          data as ISbStoryData<TeamMemberDetailPageStoryblok>
                        }
                      />
                    )}

                    {data?.content.component === 'satellite-clinic-page' && (
                      <SatelliteClinicPageHeader
                        story={
                          data as ISbStoryData<SatelliteClinicPageStoryblok>
                        }
                      />
                    )}

                    {data?.content?.body?.map((block) => (
                      <DynamicBlock key={block._uid} block={block} />
                    ))}
                  </>
                )}
              </ContentWrapper>
            </InnerWrapper>
          </Wrapper>
        </>
      )}
    </AnimatePresence>
  )
}

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.highest};

  height: 100%;
  width: 100vw;
  padding: 0.5rem;

  overflow: hidden;

  background: ${({ theme }) =>
    addAlphaToColor(theme.colors.palette.blue[900], 90)};
`

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.highest};

  height: calc(100% - 3.5rem);
  width: 100%;

  ${({ theme }) => theme.media.md} {
    width: calc(100% - 1rem);
    height: 100%;
  }

  ${({ theme }) => theme.media.lg} {
    width: auto;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  height: 100%;
  width: 100%;

  background: ${({ theme }) => theme.colors.palette.beige.default};

  ${({ theme }) => theme.media.md} {
    border-radius: 1.25rem;
  }

  ${({ theme }) => theme.media.lg} {
    width: 43.875rem;
    height: calc(100% - 1rem);
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    padding: 0.75rem 2rem;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  padding: 0 1.25rem;
`

const ButtonsHolder = styled.div`
  display: none;

  ${({ theme }) => theme.media.md} {
    display: flex;
    gap: 0.5rem;
  }
`
