import TagManager from 'react-gtm-module'

export enum GTMEvents {
  Call = 'click2call',
  OpenEvening = 'click2webinar',
  FormSubmission = 'click2form',
  Document = 'click2document',
  PortalClick = 'click2portallink',
}

export const trackEvent = (
  event: string,
  data: Record<string, string> = {}
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...data,
    },
  })
}
