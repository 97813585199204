import NextImage from 'next/image'
import styled from 'styled-components'

import { Button, Icon, Text } from 'common/UI'
import { Video } from 'common/UI/Video'
import { getImageAttributes } from 'common/utils/content'
import { GTMEvents, trackEvent } from 'common/utils/gtm'
import { isSourceType } from 'common/utils/isSourceType'
import { StoryblokAsset } from 'lib/types'

export const Media = ({
  source,
  ...props
}: {
  source: StoryblokAsset
}): JSX.Element => {
  const { name, title, filename } = source

  if (isSourceType(filename, 'video'))
    return (
      <div {...props}>
        <Video src={filename} title={title} />
        {name && (
          <Text css={{ display: 'block', marginTop: '0.5rem' }} as="span">
            {name}
          </Text>
        )}
      </div>
    )

  if (isSourceType(filename, 'pdf'))
    return (
      <PdfWrapper {...props}>
        <PdfIcon as="span" variant="sixteen">
          pdf
        </PdfIcon>
        <Text as="span" variant="sixteen" css={{ marginLeft: '0.5rem' }}>
          {title || filename}
        </Text>
        <PdfDownload variant="text">
          <a
            href={filename}
            target="_blank"
            rel="noreferrer"
            download
            onClick={() => {
              trackEvent(GTMEvents.Document, {
                'tfp-document': filename,
              })
            }}
          >
            <Icon icon="download" />
          </a>
        </PdfDownload>
      </PdfWrapper>
    )

  return (
    <figure>
      {source.filename && (
        <ImageWrapper {...props}>
          <NextImage
            {...getImageAttributes(source)}
            style={{ height: '100%' }}
          />
        </ImageWrapper>
      )}
      {name && (
        <figcaption css={{ display: 'block', marginTop: '0.5rem' }}>
          {name}
        </figcaption>
      )}
    </figure>
  )
}

const PdfWrapper = styled.div`
  display: flex;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.colors.background.subtle};
  border-radius: 0.5rem;
`

const PdfIcon = styled(Text)`
  background-color: ${({ theme }) => theme.colors.palette.pink.light};
  color: ${({ theme }) => theme.colors.palette.purple.light};
  border-radius: 0.5rem;
  padding: 0.75rem 0.65rem;
  margin-left: 0.5rem;
`

const PdfDownload = styled(Button)`
  padding: 1.75rem 1rem;
  border-left: 1px solid ${({ theme }) => theme.colors.palette.brand[300]};
  border-radius: 0;
  margin-left: auto;

  a {
    color: inherit;
    text-decoration: none;
  }
`

const ImageWrapper = styled.div`
  border-top-right-radius: 5rem;
  overflow: hidden;
  border-bottom-left-radius: 5rem;
  width: fit-content;
`
