import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css, useTheme } from 'styled-components'

import { addAlphaToColor, dashedLine } from 'common/utils/style'

import { Icon } from './Icon'
import { Container } from './Layout'

type Props = {
  itemsPerView: number
  children?: React.ReactNode
  gap?: string
  size?: 'small' | 'default'
}

export const Carousel: React.FC<Props> = ({
  children,
  itemsPerView,
  gap,
  size = 'default',
  ...props
}) => {
  const theme = useTheme()
  const [leftScroll, setLeftScroll] = useState(0)
  const carouselRef = useRef<HTMLDivElement>(null)
  const scrollBarHeight = useRef(10)
  const [paddingLeft, setPaddingLeft] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement | null>(null)

  if (typeof carouselRef.current?.clientWidth !== 'undefined') {
    scrollBarHeight.current =
      carouselRef.current?.offsetHeight - carouselRef.current?.clientHeight
  }

  const scrollTo = useCallback((left: number) => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        left,
        behavior: 'smooth',
      })
    }
  }, [])

  const handleScroll = useCallback(
    (dir: 'prev' | 'next') => {
      const ref = carouselRef.current
      if (ref) {
        const { paddingLeft, paddingRight } = getComputedStyle(ref)
        const paddings =
          Number(paddingLeft.replace('px', '')) +
          Number(paddingRight.replace('px', ''))
        const targets = {
          prev: ref?.scrollLeft - (ref.clientWidth - paddings) - 16,
          next: ref?.scrollLeft + (ref.clientWidth - paddings) + 16,
        }

        scrollTo(targets[dir])
      }
    },
    [scrollTo]
  )

  const handlePrev = handleScroll.bind(null, 'prev')
  const handleNext = handleScroll.bind(null, 'next')

  useEffect(() => {
    const setPadding = () => {
      if (containerRef.current) {
        const { left } = containerRef.current.getBoundingClientRect()
        setPaddingLeft(left)
      }
    }

    setPadding()
    window.addEventListener('resize', setPadding)

    return () => window.removeEventListener('resize', setPadding)
  }, [])

  useEffect(() => {
    const ref = carouselRef.current
    const onScroll = (evt: Event) => {
      if (
        typeof ref?.clientWidth !== 'undefined' &&
        ref?.clientWidth >= ref?.scrollWidth - ref?.scrollLeft
      ) {
        setLeftScroll(ref?.scrollWidth - ref?.clientWidth)
      } else {
        setLeftScroll((evt.currentTarget as HTMLDivElement).scrollLeft)
      }
    }

    ref?.addEventListener('scroll', onScroll)

    return () => {
      ref?.removeEventListener('scroll', onScroll)
    }
  }, [])

  const isOverflown = useMemo(() => {
    if (!carouselRef.current) return

    return carouselRef.current.scrollWidth > carouselRef.current.clientWidth
  }, [carouselRef])

  const renderChildren = Children.map(children, (child) => {
    if (child === null) return

    return (
      <Item
        paddingRight={paddingLeft}
        viewNumber={itemsPerView}
        gap={gap}
        size={size}
      >
        <div
          css={{ height: `calc(100% - ${scrollBarHeight.current || 10}px)` }}
        >
          {child}
        </div>
      </Item>
    )
  })

  return (
    <>
      <Section {...props}>
        <Wrapper>
          <CarouselWrapper
            scrollBarHeight={scrollBarHeight.current}
            paddingLeft={paddingLeft}
            ref={carouselRef}
          >
            {renderChildren}
          </CarouselWrapper>
        </Wrapper>

        <StyledContainer ref={containerRef}>
          {(Children.count(children) > itemsPerView || isOverflown) && (
            <ScrollBarWrapper>
              <Scroll />
              <Bar
                style={{
                  backgroundColor: theme.colors.foreground.default,
                  width: `${
                    (leftScroll * 100) /
                    ((carouselRef.current?.scrollWidth || 0) -
                      (carouselRef.current?.clientWidth || 0))
                  }%`,
                }}
                clientWidth={carouselRef.current?.clientWidth || 0}
                leftScroll={leftScroll}
                totalWidth={carouselRef.current?.scrollWidth || 0}
              />

              <ScrollButton
                disabled={leftScroll === 0}
                variant="left"
                onClick={handlePrev}
              >
                <Icon icon="arrow-left" />
              </ScrollButton>

              <ScrollButton
                variant="right"
                disabled={
                  typeof carouselRef.current?.clientWidth !== 'undefined' &&
                  leftScroll >=
                    carouselRef.current?.scrollWidth -
                      carouselRef.current?.clientWidth
                }
                onClick={handleNext}
              >
                <Icon icon="arrow-right" />
              </ScrollButton>
            </ScrollBarWrapper>
          )}
        </StyledContainer>
      </Section>
    </>
  )
}

const Section = styled.section`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`

const Wrapper = styled.div`
  overflow: hidden;
`

const ScrollButton = styled.button<{ variant: 'left' | 'right' }>`
  position: absolute;
  top: 0;
  bottom: 0;

  ${({ variant }) => css`
    ${variant}: -3em;
  `}

  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;

  ${({ disabled, theme }) => {
    if (disabled) {
      return css`
        opacity: 0.5;
      `
    }

    return css`
      @media (hover: hover) {
        :hover {
          background-color: ${addAlphaToColor(
            theme.colors.foreground.default,
            10
          )};
        }
      }
    `
  }}

  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 20)};
  border-radius: 50%;
`

const StyledContainer = styled(Container)`
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  ${({ theme }) => theme.media.md} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`

const ScrollBarWrapper = styled.div`
  position: relative;
  padding: 2.5rem;
  margin: 0 3rem;
`

const Bar = styled.div<{
  totalWidth: number
  leftScroll: number
  clientWidth: number
}>`
  margin: auto;
  overflow: hidden;

  max-width: 100%;
  height: 1px;

  position: absolute;
  top: 50%;
  left: 0%;

  background-color: ${({ theme }) => theme.colors.foreground.default};
`

const Scroll = styled.div`
  position: absolute;
  left: 0;
  top: 50%;

  margin: auto;
  padding: 0 30px;

  width: 100%;
  height: 1px;
  ${({ theme }) => dashedLine('bottom', theme.colors.foreground.subtle)};
`

const Item = styled.div<{
  viewNumber: number
  paddingRight: number
  gap?: string
  size: 'small' | 'default'
}>`
  min-width: ${({ size }) => (size === 'small' ? '52%' : '95%')};
  width: 95%;
  box-sizing: border-box;

  :not(:first-child) {
    margin-left: 1rem;
  }

  box-sizing: content-box;
  :last-child {
    padding-right: 1rem;
  }

  ${({ theme }) => theme.media.md} {
    min-width: calc(50% - 1rem);
    width: calc(50% - 1rem);
  }

  ${({ theme }) => theme.media.lg} {
    min-width: ${({ viewNumber }) =>
      `calc(${100 / viewNumber}% - ${
        viewNumber > 1 ? `(1rem * ${(viewNumber - 1) / viewNumber})` : '0px'
      })`};
    width: ${({ viewNumber }) =>
      `calc(${100 / viewNumber}% - ${
        viewNumber > 1 ? `(1rem * ${(viewNumber - 1) / viewNumber})` : '0px'
      })`};

    :last-child {
      padding-right: calc(${({ paddingRight }) => paddingRight}px + 1.25rem);
    }

    :not(:first-child) {
      margin-left: ${({ gap }) => `${gap || '2.5rem'}`};
    }
  }
`

const CarouselWrapper = styled.div<{
  paddingLeft: number
  scrollBarHeight: number
}>`
  display: flex;
  position: relative;
  height: ${({ scrollBarHeight }) => `calc(100% + ${scrollBarHeight || 10}px)`};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? `${paddingLeft + 20}px` : '1.25rem'};
  padding-right: ${({ paddingLeft }) =>
    paddingLeft ? `${paddingLeft + 20}px` : '1.25rem'};

  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  // Hide Scrollbar
  ::-webkit-scrollbar {
    // Chrome, Safari, Opera
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${({ theme }) => theme.media.lg} {
    padding-left: ${({ paddingLeft }) =>
      paddingLeft ? `${paddingLeft + 16}px` : '5rem'};
    padding-right: ${({ paddingLeft }) =>
      paddingLeft ? `${paddingLeft + 16}px` : '2rem'};
  }
`
